
import request from './url'

// //请求本项目的serve.js
// export const serve = async({...params}) => {
//   
//  const res = await ({
//      url: 'http://localhost:3000/',
//      method: 'get',
//      data:params,
//  })
//  return res.data
// }

//获取key
export const keyCode = async({...params}) => {
   
  const res = await request({
      url: '/api/v1/web/jwt/key',
      method: 'post',
      data:params,
  })
  return res.data
}

// 商标分类查看搜索

//拿key来获取全部大类
export const tranClassApi= async({...params}) => {
  
 const res = await request({
     url: '/category/api/v1/web/categroy/all_category',
     method: 'post',
     data:params,
 })
 return res.data
}

//拿key来获取全部小项和群组
export const tranClassGroupApi= async({...params}) => {
  
 const res = await request({
     url: '/category/api/v1/web/categroy/all_general',
     method: 'post',
     data:params,
 })
 return res.data
}

//拿key来搜索大类
export const transousuoClassApi= async({...params}) => {
  
 const res = await request({
     url: '/category/api/v1/web/categroy/search_category',
     method: 'post',
     data:params,
 })
 return res.data
}

//拿key来搜索小项
export const transousuoClassGroupApi= async({...params}) => {
  
 const res = await request({
     url: '/category/api/v1/web/categroy/search_general',
     method: 'post',
     data:params,
 })
 return res.data
}

// 商标查询


//智能搜索
export const intelligentSearch = async({...params}) => {
 const res = await request({
     url: '/api/v1/web/traderamk/samrt',
     method: 'post',
     data:params,
 })
 return res.data
}

//精准搜索
export const preciseSearch = async({...params}) => {
 const res = await request({
     url: '/api/v1/web/traderamk/precise',
     method: 'post',
     data:params,
 })
 return res.data
}

//注册号搜索
export const registerSearch = async({...params}) => {
  
 const res = await request({
     url: '/api/v1/web/traderamk/reg_id',
     method: 'post',
     data:params,
 })
 return res.data
}

//条件搜索
export const conditionSearch = async({...params}) => {
 const res = await request({
     url: '/api/v1/web/traderamk/condition_search',
     method: 'post',
     data:params,
 })
 return res.data
}

//申请人搜索
export const applicantSearch = async({...params}) => {
 const res = await request({
     url: '/api/v1/web/traderamk/proposer',
     method: 'post',
     data:params,
 })
 return res.data
}

//查看单个商标详情
export const detiail = async({...params}) => {
 const res = await request({
     url: '/api/v1/web/traderamk/detiail',
     method: 'post',
     data:params,
 })
 return res.data
}

//提交信息
export const message = async({...params}) => {
  
 const res = await request({
     url: '/api/v1/web/message/submit_message',
     method: 'post',
     data:params,
 })
 return res.data
}

//获取ip地址
export const newIP = async({...params}) => {
  
 const res = await request({
     url: '/api/v1/4yPOYTsZijbkavZMD1',
     method: 'get',
     data:params,
 })
 return res.data
}