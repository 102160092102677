import { render, staticRenderFns } from "./drawer.vue?vue&type=template&id=6d859879&scoped=true&"
import script from "./drawer.vue?vue&type=script&lang=js&"
export * from "./drawer.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./drawer.vue?vue&type=style&index=0&id=6d859879&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6d859879",
  "483dbeca"
  
)

export default component.exports