<template>
  <div class="sidebar">
    <!-- 在线客服 -->
    <a href="https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9">
    <el-popover placement="end" width="0px" :hide-after=0  :offset="25"  @show="fn5" @hide="clossfn5" >
      <template #reference>
        <div class="box" :style="{backgroundColor: show5==false? '#4D89F1' : '#4D89F1'}">
          <div class="img">
            <div class="png" :id="[show5==false? 'icon05Hover' : 'icon05Hover']"></div>
          </div>
          <span class="word01" :style="{color: show5==false? '#fff' : '#fff'}">在线客服</span>
        </div>
      </template>
    </el-popover>
    </a>
<!-- 微信客服 -->
    <el-popover placement="left" :hide-after=0  :offset="25"  trigger="hover" @show="fn3" width="16rem" @hide="clossfn3">
      <template #reference>
        <div class="box" :style="{backgroundColor: show3==false? '#fff' : '#4D89F1'}">
          <div class="img">
            <div class="png" :id="[show3==false? 'icon03' : 'icon03Hover']"></div>
          </div>
          <span class="word01" :style="{color: show3==false? '#999999' : '#fff'}">微信客服</span>
        </div>
      </template>
      <div class="wxbox margin">
        <img src="../imger/wx01.png" alt="查商标,商标查询,商标注册,商标申请,">
        <div class="wxboxword">
          <p>潘老师：</p>
          <p>18126748819</p>
        </div>
      </div>
      <div class="wxbox">
        <img src="../imger/wx02.png" alt="查商标,商标查询,商标注册,商标申请,">
        <div class="wxboxword">
          <p>李老师：</p>
          <p>13380036087</p>
        </div>
      </div>
    </el-popover>

    <!-- 官方热线 -->
    <el-popover placement="left" width="12.5rem" :hide-after=0 :offset="25"  trigger="hover" @show="fn1" @hide="clossfn1">
      <template #reference>
        <div class="box" :style="{backgroundColor: show1==false? '#fff' : '#4D89F1'}">
          <div class="img" >
            <div class="png" :id="[show1==false? 'icon01' : 'icon01Hover']" ></div>
          </div>
          <span class="word01" :style="{color: show1==false? '#999999' : '#fff'}">官方热线</span>
        </div>
      </template>
      <div class="gfrx">
        <span class="word02" style="font-weight: bold;">官方热线：</span>
        <br>
        <span class="word02">400-850-8819</span>
      </div>
    </el-popover>

    <!-- 小程序 -->
    <el-popover placement="left" width="11.5rem" :hide-after=0  :offset="25" trigger="hover" @show="fn2" @hide="clossfn2">
      <template #reference>
        <div class="box" :style="{backgroundColor: show2==false? '#fff' : '#4D89F1'}">
          <div class="img">
            <div class="png" :id="[show2==false? 'icon02' : 'icon02Hover']"></div>
          </div>
          <span class="word01" :style="{color: show2==false? '#999999' : '#fff'}">小程序</span>
        </div>
      </template>
      <div class="xcxbox">
        <img src="../imger/xcx.png" class="xcx" alt="问标网小程序二维码">
        <p class="xcxword">
          <img src="../imger/sidebar/up.png" class="xcxup" alt="问标网">
          <span>小程序</span>
        </p>
      </div>
      <div class="xcxbox">
        <img src="../imger/gzh.png" class="xcx" alt="问标网公众号二维码">
        <p class="xcxword">
          <img src="../imger/sidebar/up.png" class="xcxup" alt="问标网">
          <span>公众号</span>
        </p>
      </div>
    </el-popover>

    <!-- 闲置商标 -->
    <el-popover placement="left" width="11.5rem" :hide-after=0  :offset="25"  trigger="hover" @show="fn4" @hide="clossfn4">
      <template #reference>
        <div class="box" :style="{backgroundColor: show4==false? '#fff' : '#4D89F1'}">
          <div class="img">
            <div class="png" :id="[show4==false? 'icon04' : 'icon04Hover']"></div>
          </div>
          <span class="word01" :style="{color: show4==false? '#999999' : '#fff'}">闲置商标</span>
        </div>
      </template>
      <div class="xcxbox">
        <img src="../imger/biaoyuan.png" class="xcx" alt="标猿小程序二维码">
        <p class="xcxword">
          <img src="../imger/sidebar/up.png" class="xcxup" alt="问标网">
          <span>小程序</span>
        </p>
      </div>
    </el-popover>

    
  </div>
</template>

<script>
export default {
      data(){
        return {
          show1:false,
          show2:false,
          show3:false,
          show4:false,
          show5:false,
        }
      },
      methods: {
        fn1() {
          this.show1 = true
        },
        clossfn1(){
          this.show1 = false
        },
        fn2() {
          this.show2 = true
        },
        clossfn2(){
          this.show2 = false
        },
        fn3() {
          this.show3 = true
        },
        clossfn3(){
          this.show3 = false
        },
        fn4() {
          this.show4 = true
        },
        clossfn4(){
          this.show4 = false
        },
        fn5() {
          this.show5 = true
        },
        clossfn5(){
          this.show5 = false
        },
        going(){
          this.$router.push({path:'https://www14c1.53kf.com/webCompany.php?arg=10248253&style=1&code_arg=b5d0f2c76a96fff81d61e378ffd7c74d9'})
        }
      }
}
</script>

<style scoped lang="scss">
.xcx{
  width: 9.375rem /* 150px -> 9.375rem */;
  height: 9.375rem /* 150px -> 9.375rem */;
}

#icon01 {
  background-image: url("../imger/sidebar/phone02.png");
  background-size: contain;
}
#icon01Hover {
  background-image: url("../imger/sidebar/phone01.png");
  background-size: contain;
}
#icon02 {
  background-image: url("../imger/sidebar/xcx02.png");
  background-size: contain;
}
#icon02Hover {
  background-image: url("../imger/sidebar/xcx01.png");
  background-size: contain;
}
#icon03 {
  background-image: url("../imger/sidebar/wx1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
#icon03Hover {
  background-image: url("../imger/sidebar/wx2.png");
  background-size: contain;
  background-repeat: no-repeat;
}
#icon04 {
  background-image: url("../imger/sidebar/xztran02.png");
  background-size: contain;
}
#icon04Hover {
  background-image: url("../imger/sidebar/xztran01.png");
  background-size: contain;
}
#icon05 {
  background-image: url("../imger/sidebar/kf02.png");
  background-size: contain;
}
#icon05Hover {
  background-image: url("../imger/sidebar/kf01.png");
  background-size: contain;
}

.margin{
  margin-bottom: 1.875rem /* 30px -> 1.875rem */;
}
.wxbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wxboxword{
    height: 3.75rem /* 60px -> 3.75rem */;
    font-size: 1rem /* 16px -> 1rem */;
    color: #333333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.xcxbox{
  display: flex;
  flex-direction: column;
  align-items: center;
  .xcxword{
    height: 1.875rem /* 30px -> 1.875rem */;
    font-size: 1rem /* 16px -> 1rem */;
    color: #333333;
    display: flex;
    align-items: center;
    .xcxup{
      width: 0.8125rem /* 13px -> 0.8125rem */;
      height: 0.625rem /* 10px -> 0.625rem */;
      margin-right: 0.3125rem /* 5px -> 0.3125rem */;
    }
  }
}
.gfrx{
    margin-top: 1.25rem /* 20px -> 1.25rem */;
    margin-bottom: 1.25rem /* 20px -> 1.25rem */;
    .word02 {
      font-size:1rem /* 16px -> 1rem */;
      font-weight: bold;
      line-height:1.875rem /* 30px -> 1.875rem */;
    }
  }
.sidebar {
  width: 4.375rem /* 70px -> 4.375rem */;
  background-color: #fff;
  box-shadow: 0 0 1.25rem /* 20px -> 1.25rem */ rgb(163, 163, 163);
  position: fixed;
  right: 0;
  top:9.375rem /* 150px -> 9.375rem */;
  .box {
    height: 6.125rem /* 98px -> 6.125rem */;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      height: 3.75rem /* 60px -> 3.75rem */;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .png {
        height: 2.5rem /* 40px -> 2.5rem */;
        width: 2.5rem /* 40px -> 2.5rem */;
      }
    }
    .word01 {
      font-size: 0.9375rem /* 15px -> 0.9375rem */;
      color: #999999;
    }
  }
  
  
}
</style>