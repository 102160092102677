<template>
  <div id="index">
    <!-- 头部组件 -->
    <Header />

    <!-- 搜索 -->
    <div class="sousou">
      <div class="content">
        <!-- logo 以及栏目导航 -->
        <div class="row">
          <img src="../imger/WBWlogo02.jpg" title="问标网" class="icon2" alt="问标网" />
          <div class="trademark">
            <router-link to="/sblb"
              ><a href="" id="W01">商标分类</a></router-link
            ><span>|</span>
            <router-link to="/zcsb"
              ><a href="" id="W01">注册商标</a></router-link
            ><span>|</span>
            <router-link to="/server"
              ><a href="" id="W01">服务中心</a></router-link
            ><span>|</span>
            <router-link to="/news"
              ><a href="" id="W01">新闻资讯</a></router-link
            ><span>|</span>
            <router-link to="/about"
              ><a href="" id="W01">关于我们</a></router-link
            ><span>|</span>
            <router-link to="/contact"
              ><a href="" id="W01">联系我们</a></router-link
            >
          </div>
        </div>
        <h1 id="title">免费查商标 就用问标网</h1>
        <div class="title2">
          超6000万全国商标注册大数据实时更新，查商标好助手
        </div>
        <!-- 搜索栏 -->
        <div class="put">
          <div class="bar">
            <input
              class="input01"
              type="text"
              placeholder="请输入商标名称、申请人名称、申请号"
              v-model="tradName"
              @keyup.enter="tradgo"
            />
          </div>
          <div class="anniu" @click="tradgo">
            <img src="../imger/sousuo02.png" class="icon3" alt="问标网，免费查商标" />
          </div>
        </div>
        <!-- 加打钩的查询类型 -->
        <div class="HOOK">
          <div class="hookbox" >
            <img src="../imger/gou.png" alt="中文查询" />
            <span class="HBword">中文查询</span>
          </div>
          <div class="hookbox">
            <img src="../imger/gou.png" alt="英文查询" />
            <span class="HBword">英文查询</span>
          </div>
          <div class="hookbox">
            <img src="../imger/gou.png" alt="申请人查询" />
            <span class="HBword">申请人查询</span>
          </div>
          <div class="hookbox">
            <img src="../imger/gou.png" alt="状态查询" />
            <span class="HBword">状态查询</span>
          </div>
          <div class="hookbox">
            <img src="../imger/gou.png" alt="申请号查询" />
            <span class="HBword">申请号查询</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 居中内容部分 -->
    <div class="ware">
      <!-- 商标服务分类 -->
      <div class="classification">
        <!-- 商标服务三大分类 -->
        <div class="top">
          <!-- 查闲置商标 第一个分类-->
          <router-link to="/success">
            <div class="box">
              <div class="left">
                <img src="../imger/xzTran.png" alt="查商标成功率" class="tabicon01" />
              </div>
              <div class="right">
                <p class="word01">查商标成功率</p>
                <p class="word02">
                  商标成功率基于商标全量大数据，根据商标同名、近似、敏感词等维度进行智能分析商标注册风险。
                </p>
              </div>
            </div>
          </router-link>
          <!-- 查闲置商标 第二个分类-->
          <router-link to="/sblb">
            <div class="box">
              <div class="left">
                <img src="../imger/tranClass.png" alt="商标分类查询" class="tabicon01" />
              </div>
              <div class="right">
                <p class="word01">商标分类查询</p>
                <p class="word02">
                  商标分类根据国际统一商标分类表《尼斯分类》划分，有34个产品大类、11个服务大类，合计45个大类。
                </p>
              </div>
            </div>
          </router-link>
          <!-- 查闲置商标 第三个分类-->
          <router-link to="/zcsb">
            <div class="box">
              <div class="left">
                <img src="../imger/tranResg.png" alt="在线注册商标" class="tabicon01" />
              </div>
              <div class="right">
                <p class="word01">在线注册商标</p>
                <p class="word02">
                  问标网智能注册商标系统2.0已开通在线注册商标服务，用户注册/登录后即可在线自主提交注册商标。
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <!-- 轮播图 -->
        <div class="carousel">
          <el-carousel trigger="click" height="8.5rem">
            <el-carousel-item :key="1">
              <img src="../imger/lunbo01.png" alt="在线注册商标" class="icon01" @click="tukc()" />
            </el-carousel-item>
            <el-carousel-item :key="2">
              <img src="../imger/lunbo02.png" alt="百万现成商标" class="icon02" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 热门企业服务 -->
      <div class="service">
        <div class="top">
          <div class="left">
            <div class="lan"></div>
            <p class="title">热门企业服务</p>
          </div>
          <div class="right">
            <router-link to="/server">
              <a class="mach">更多服务</a>
              <img src="../imger/come02.png" alt="问标网" class="come02" />
            </router-link>
          </div>
        </div>
        <!-- 各大项服务的标价 -->
        <div class="bottom">
          <!-- 商标在线自主注册 -->
          <div class="boxfater" v-for="(item, index) in boxdata" :key="index">
            <router-link  target="_blank" :to="item.url">
              <div class="box" :style="{ background: item.color }">
                <div class="boxWera">
                  <p class="word03">{{ item.word03 }}</p>
                  <p class="word04" :style="{ color: item.wordColor }">
                    {{ item.word04 }}
                  </p>
                  <span class="price"
                    >{{ item.price }}<span class="Company">元</span
                    ><span class="Company">{{ item.qi }}</span></span
                  >
                </div>
                <div class="picture">
                  <img :src="item.icon" :alt="item.word03" class="icon04" />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 行业热点新闻 -->
      <Journalism />
    </div>
    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>
<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Journalism from "../components/index/Journalism.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";

import icon01 from "../imger/icon01.png";
import icon02 from "../imger/icon02.png";
import icon03 from "../imger/icon03.png";
import icon04 from "../imger/icon04.png";
import icon05 from "../imger/icon05.png";
import icon06 from "../imger/icon06.png";

import { keyCode, newIP } from "../API/index";
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'

export default {
  components: {
    Header,
    Footer,
    Sidebar,
    Journalism,
    Eject,
    Drawer,
  },
  data() {
    return {
      boxdata: [
        {
          icon: icon01,
          word03: "专家辅助注册商标",
          word04: "全程1对1人工服务",
          price: 580,
          url: "/coach",
          color: "#E5FAFA",
          wordColor: "#06CACA",
        },
        {
          icon: icon02,
          word03: "商标保险注册",
          word04: "不成功免费重新注册",
          price: 980,
          url: "/bxzc",
          color: "#EAF2FE",
          wordColor: "#5A92EE",
        },
        {
          icon: icon03,
          word03: "无执照注册商标",
          word04: "创业未动·商标先行",
          price: 1000,
          url: "/license",
          color: "#FCE8E8",
          wordColor: "#EE434C",
        },
        {
          icon: icon04,
          word03: "商标驳回复审",
          word04: "资深商标律师亲自服务",
          price: 2850,
          url: "/reject",
          color: "#EDE9FF",
          wordColor: "#6A3AE0",
        },
        {
          icon: icon05,
          word03: "品牌/商标LOGO设计",
          word04: "只做高端原创LOGO设计",
          price: 2380,
          qi: "起",
          url: "/logo",
          color: "#FEFCE9",
          wordColor: "#EBB806",
        },
        {
          icon: icon06,
          word03: "全网品牌搜索占位",
          word04: "品牌全网搜索优先展现",
          price: 15800,
          qi: "起",
          url: "/seat",
          color: "#FFF1EA",
          wordColor: "#F17229",
        },
      ],
      tradName: "",
      ip: "",
    };
  },

  created() {
    // if(this.$route.query.userData){
    //   this.userData = JSON.parse(this.$route.query.userData)
    // }
    this.keyCode();
  },
  methods: {
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == null) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    tradgo() {
      if (this.tradName) {
        var keys = generatekey();
        let obj = {
          tradName: this.tradName,
          type:"smark",
          page:1,
        }
        var sign = encryptDes(JSON.stringify(obj),keys);

        this.$router.push({
          path: `/rearch/${sign}.html`,
        });
      } else {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      }
    },

    tukc(){
     this.$router.push({ path: "/zcsb" }); 
    }
  },
};
</script>


<style scoped lang="scss">
// icon01：商标服务图标、icon02和icon03：轮播图

// word01和word02：商标服务文字内容
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.icon01 {
  width: 100%;
  cursor: pointer;
}
.icon02 {
  width: 100%;
}

#index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sousou {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    background-image: url("../imger/indexback.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-content: center;
    .content {
      height: 100%;
      width: 74.9375rem /* 1199px -> 74.9375rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      .row {
        width: 100%;
        .icon2 {
          width: 8.4375rem /* 135px -> 8.4375rem */;
          float: left;
          display: block;
        }
        .trademark {
          margin-top: 1.625rem /* 26px -> 1.625rem */;
          float: right;
          color: #fff;
          display: flex;
          justify-content: space-between;
          width: 33.5rem /* 536px -> 33.5rem */;
          font-size: 0.875rem /* 14px -> .875rem */;
          #W01 {
            color: #fff;
            font-size: 0.875rem /* 14px -> .875rem */;
            text-decoration: none;
          }
        }
      }

      #title {
        margin-top: 3.75rem /* 60px -> 3.75rem */;
        color: #fff;
        font-size: 3.140625rem /* 50.25px -> 3.140625rem */;
        font-weight: 600;
        letter-spacing: 0.1875rem /* 3px -> 0.1875rem */;
      }
      .title2 {
        margin-top: 1.4375rem /* 23px -> 1.4375rem */;
        width: 31rem /* 496px -> 31rem */;
        height: 3.125rem /* 50px -> 3.125rem */;
        color: #fff;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        border-radius: 1.5625rem /* 25px -> 1.5625rem */;
        background-color: rgba($color: #fff, $alpha: 0.22);
        text-align: center;
        line-height: 3.125rem /* 50px -> 3.125rem */;
      }
      .put {
        margin-top: 1.625rem /* 26px -> 1.625rem */;
        width: 46.75rem /* 748px -> 46.75rem */;
        height: 3.75rem /* 60px -> 3.75rem */;
        display: flex;
        .bar {
          width: 38.9375rem /* 623px -> 38.9375rem */;
          height: 100%;
          border-top-left-radius: 0.625rem /* 10px -> .625rem */;
          border-bottom-left-radius: 0.625rem /* 10px -> .625rem */;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          .input01 {
            border: none;
            height: 80%;
            width: 90%;
            outline: none;
            font-size: 1.25rem /* 20px -> 1.25rem */;
          }
        }
        .anniu {
          width: 7.8125rem /* 125px -> 7.8125rem */;
          height: 100%;
          border-top-right-radius: 0.625rem /* 10px -> .625rem */;
          border-bottom-right-radius: 0.625rem /* 10px -> .625rem */;
          background-color: #ff7e00;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon3{
            width: 1.6875rem /* 27px -> 1.6875rem */;
            transition-duration: .1s;
            cursor: pointer;
          }
          .icon3:hover{
            transform: scale(1.2);
          }
        }
      }
      .HOOK {
        width: 46.75rem /* 748px -> 46.75rem */;
        height: 3.125rem /* 50px -> 3.125rem */;
        display: flex;
        align-items: center;
        .hookbox {
          height: 1.3125rem /* 21px -> 1.3125rem */;
          margin-right: 2.1875rem /* 35px-> 2.1875rem */;
          .HBword {
            margin-left: 0.75rem /* 12px -> .75rem */;
            color: #fff;
          }
        }
      }
    }
  }
  .ware {
    width: 74.9375rem /* 1199px -> 74.9375rem */;
    .classification {
      height: 19.25rem /* 308px -> 19.25rem */;
      width: 100%;
      display: flex;
      flex-direction: column;
      .top {
        height: 10.625rem /* 170px -> 10.625rem */;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .box:hover{
          .right{
            .word01{
              color: #4D89F1;
            }
          }
        }
        .box {
          flex-grow: 1;
          display: flex;
          transition-duration: 0.3s;
          .left {
            height: 7.375rem /* 118px -> 7.375rem */;
            width: 7.375rem /* 118px -> 7.375rem */;
            display: flex;
            justify-content: center;
            align-items: center;
            .tabicon01 {
              height: 4rem /* 64px -> 4rem */;
              width: 4rem /* 64px -> 4rem */;
              background-size: contain;
            }
          }
          .right {
            width: 15.625rem /* 250px -> 15.625rem */;
            .word01 {
              font-size: 1.0625rem /* 17px -> 1.0625rem */;
              font-weight: bold;
              color: #373737;
              margin-top: 0.875rem /* 14px -> 0.875rem */;
            }
            .word02 {
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #777777;
              margin-top: 0.625rem /* 10px -> 0.625rem */;
            }
          }
        }
      }
      .carousel {
        height: 0;
        flex-grow: 1;

        .el-carousel__item h3 {
          color: #475669;
          font-size: 18px;
          height: 26.625rem /* 426px -> 26.625rem */;
          opacity: 0.75;
          margin: 0;
          text-align: center;
        }
      }
    }
    .service {
      width: 100%;
      margin-top: 2.0625rem /* 33px -> 2.0625rem */;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0.3125rem /* 5px -> 0.3125rem */ 0.3125rem
        /* 5px -> 0.3125rem */ #f5f4f4;
      .top {
        height: 2.1875rem /* 35px -> 2.1875rem */;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          height: 100%;
          display: flex;
          .lan {
            width: 0.375rem /* 6px -> 0.375rem */;
            height: 100%;
            background-color: #508df1;
            margin-right: 0.75rem /* 12px -> 0.75rem */;
          }
          .title {
            font-size: 1.5rem /* 24px -> 1.5rem */;
            color: #333333;
            line-height: 2.0625rem /* 33px -> 2.0625rem */;
          }
        }
        .right {
          display: flex;
          align-items: center;
          .mach {
            font-size: 1rem /* 16px -> 1rem */;
            color: #666666;
            margin-right: 0.5625rem /* 9px -> 0.5625rem */;
          }
          .come02 {
            width: 0.5625rem /* 9px -> 0.5625rem */;
            height: 1rem /* 16px -> 1rem */;
          }
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        margin-top: 1.875rem /* 30px -> 1.875rem */;
        .boxfater:hover{
          .box{
            .boxWera{
              .word03{
                color: #4D89F1;
              }
            }
          }
        }
        .boxfater {
          margin-bottom: 1.875rem /* 30px -> 1.875rem */;
          transition-duration: .3s;
          .box {
            width: 23.625rem /* 378px -> 23.625rem */;
            height: 9.875rem /* 158px -> 9.875rem */;
            padding: 0.875rem /* 14px -> 0.875rem */;
            border-radius: 0.3125rem /* 5px -> 0.3125rem */;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .boxWera {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .word03 {
                font-size: 1.25rem /* 20px -> 1.25rem */;
                font-weight: bold;
                color: #343434;
                margin-bottom: 1.25rem /* 20px -> 1.25rem */;
              }
              .word04 {
                padding: 0.3125rem /* 5px -> 0.3125rem */;
                box-sizing: border-box;
                font-size: 0.875rem /* 14px -> 0.875rem */;
                border: 0.0625rem /* 1px -> 0.0625rem */ solid;
                border-radius: 1rem /* 16px -> 1rem */;
              }
              .price {
                font-size: 1.875rem /* 30px -> 1.875rem */;
                color: #ff7e00;
                .Company {
                  font-size: 0.875rem /* 14px -> 0.875rem */;
                  color: #333333;
                }
              }
            }
            .picture {
              height: 5rem /* 80px -> 5rem */;
              width: 5rem /* 80px -> 5rem */;
              .icon04 {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .Journalism {
      height: 39.25rem /* 628px -> 39.25rem */;
      width: 100%;
    }
  }
}
</style>
