import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=7169ccd2&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./footer.vue?vue&type=style&index=0&id=7169ccd2&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7169ccd2",
  "17447845"
  
)

export default component.exports