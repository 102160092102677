// 客户端渲染时加载的main.js (原因是服务端上不支持window、dom等api)
import "@/config/rem.js"     //使用了window的api
import '@/config/href.js'     //使用了window的api
import Vue from 'vue'
import App from './App.vue'
// import router from './router'
// import store from './store'
import createRouter from './router'
import createStore from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false

Vue.use(ElementUI);

export default function createApp() {
  const router = createRouter();
  const store = createStore();
  
  const app = new Vue({
      router,
      store,
      render: h => h(App)
  });

    // 全局路由守卫
router.beforeEach((to, from, next) => {

  // to: Route: 即将要进入的目标 路由对象

  // from: Route: 当前导航正要离开的路由

  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0

  window.scroll(0, 0);

  next();

});

  return { app, router }
}

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
