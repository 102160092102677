import { render, staticRenderFns } from "./Journalism.vue?vue&type=template&id=9b4a2f0e&scoped=true&"
import script from "./Journalism.vue?vue&type=script&lang=js&"
export * from "./Journalism.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Journalism.vue?vue&type=style&index=0&id=9b4a2f0e&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9b4a2f0e",
  "1d354200"
  
)

export default component.exports