import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  // 商标分类
  {
    path: '/sblb',
    name: 'sblb',
    component: () => import('../views/sblb.vue'),
  },
  // 登录界面
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login.vue')
  },
  // 商标查询结果分类页
  {
    path: '/rearch/:sign',
    name: 'rearch',
    component: () => import( '../views/rearch.vue'),
  },
  // 商标详情页
  {
    path: '/details/:sign',
    name: 'details',
    component: () => import( '../views/details.vue')
  },
  // 个人用户信息
  {
    path: '/user',
    name: 'user',
    component: () => import( '../views/user.vue')
  },
  // 商标在线自主注册页
  {
    path: '/zcsb',
    name: 'zcsb',
    component: () => import( '../views/zcsb.vue')
  },
  // 商标辅助注册页
  {
    path: '/coach',
    name: 'coach',
    component: () => import( '../views/coach.vue')
  },
  // 品牌设计页面
  {
    path: '/logo',
    name: 'logo',
    component: () => import( '../views/logo.vue')
  },
  // 网站建设页面
  {
    path: '/wzjs',
    name: 'wzjs',
    component: () => import( '../views/wzjs.vue')
  },
  // 搜索站位页面
  {
    path: '/seat',
    name: 'seat',
    component: () => import( '../views/seat.vue')
  },
  // 关于我们页面
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/about.vue')
  },
  // 联系我们页面
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/contact.vue')
  },
  // 新闻页面
  {
    path: '/news',
    name: 'news',
    component: () => import( '../views/news.vue'),
  },
  // 新闻信息页面
  {
    path: '/newInfo/:sign',
    name: 'newInfo',
    component: () => import( '../views/newInfo.vue')
  },
  // 查商标成功率
  {
    path: '/success',
    name: 'success',
    component: () => import( '../views/success.vue')
  },
  // 服务中心
  {
    path: '/server',
    name: 'server',
    component: () => import( '../views/server.vue')
  },
  // 商标保险注册
  {
    path: '/bxzc',
    name: 'bxzc',
    component: () => import( '../views/bxzc.vue')
  },
  // 无执照注册
  {
    path: '/license',
    name: 'license',
    component: () => import( '../views/license.vue')
  },
  // 商标驳回复审
  {
    path: '/reject',
    name: 'reject',
    component: () => import( '../views/reject.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}


// ssr 输出
export default function createRouter() {
  return new VueRouter({
      mode: 'history',
      routes,
  });
}
//普通输出
// export default router
