<template>
  <!--固定弹窗按钮 -->
  <div class="ejectbox" @click="fn1">
    <div class="right">
      <img src="../imger/tuceng511.png" alt="问标网" class="icon01">
    </div>
    <div class="left">
      <div class="open">打开小程序</div>
      <p class="word01">免费<br>查商标</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    fn1(){
      var ejectbox = document.querySelector('.ejectbox')
      var draweIndex = document.querySelector('.draweIndex')
      // ejectbox.style.transform="translatex("+ -8.125+"rem"+")"   //将窗口关闭出来
      ejectbox.style.left= -9.0625 /* -145px -> -9.0625rem */+"rem"   //将窗口关闭出来
      draweIndex.style.left = 0     //将抽屉显示出来
    }
  }

}
</script>

<style scoped lang="scss">
.ejectbox:hover {
    transform: translatex(0.9375rem /* 15px -> 0.9375rem */);
  }
.ejectbox {
  position: fixed;
  left: -.9375rem /* -15px -> -0.9375rem */;
  bottom: 6.25rem /* 100px -> 6.25rem */;
  z-index: 10;
  height: 8.48125rem /* 135.7px -> 8.48125rem */;
  width: 9.0625rem /* 145px -> 9.0625rem */;
  background: linear-gradient(to right,#4D89F1,#1A9DEE );
  border-top-right-radius: 0.625rem /* 10px -> 0.625rem */;
  border-bottom-right-radius: 0.625rem /* 10px -> 0.625rem */;
  transition-duration: 0.5s;          //设置该弹窗盒子的改变样式时的时间
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  .left {
    width: 6.875rem /* 110px -> 6.875rem */;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .open {
      width: 5.3125rem /* 85px -> 5.3125rem */;
      height: 2.125rem /* 34px -> 2.125rem */;
      background-color: #D5B97D;
      border-top-left-radius: 1.25rem /* 20px -> 1.25rem */;
      border-top-right-radius: 0.3125rem /* 5px -> 0.3125rem */;
      border-bottom-right-radius: 1.25rem /* 20px -> 1.25rem */;
      border-bottom-left-radius: 0.3125rem /* 5px -> 0.3125rem */;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      text-align: center;
      color: #fff;
      line-height: 2.125rem /* 34px -> 2.125rem */;
    }
    .word01 {
      font-size: 1.941875rem /* 31.07px -> 1.941875rem */;
      text-align: center;
      color: #fff;
    }
  }
  .right {
    height: 100%;
    width: 1.375rem /* 22px -> 1.375rem */;
    border-top-right-radius: 0.625rem /* 10px -> 0.625rem */;
    border-bottom-right-radius: 0.625rem /* 10px -> 0.625rem */;
    background-color: #95CBFF;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon01 {
      width: 0.75rem /* 12px -> 0.75rem */;
      height: 1.25rem /* 20px -> 1.25rem */;
    }
  }
}
</style>