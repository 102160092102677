<template>
  <div id="index">
    <!-- <router-link to="/"></router-link> -->
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
// 接口引入
import { keyCode, newIP } from "./API/index";
import { newkeyCode } from "./API/login";
export default {
  data() {
    return {
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  async beforeCreate() {
    // 获取key请求
    // 获取用户的IP地址（此处做有跨域）
    let ip = "";
    const resIP = await newIP();
    if (resIP.msg == "success") {
      ip = resIP.data;
    }
    let duan = sessionStorage.getItem("key");
    if (duan == null) {
      const res = await keyCode({
        // 获取客户端的ip地址
        ip: ip,
        platform: "web",
      });
      if ((res.msg = "success")) {
        await sessionStorage.setItem("key", res.data);
      }
    }

    if (localStorage.getItem("user") !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      let time = new Date().getTime();
      if (time - user.createTime >= 86400000) {
        // 如果超过一天时间没有登录，则重新登录
        localStorage.removeItem("user");
      } else {
        // 在一天时间只能不用重新登录
        const reskey = await newkeyCode({
          key: sessionStorage.getItem("key"),
          userid: user.id,
          phone: user.phone,
        });
        if ((reskey.msg = "success")) {
          await sessionStorage.setItem("userkey", reskey.data);
          // 刷新时间
          user.createTime = time
          localStorage.setItem('user',JSON.stringify(user))
        }

      }
    }
  },
  created(){
    this.keyCode();
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>


<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#index {
  width: 100%;
  min-width:1490px;
  max-width: 100%;
  overflow: hidden;
}
a {
  text-decoration: none;
}
</style>
