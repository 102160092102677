<template>
  <!-- 点击弹出之后的弹出框 -->
    <div class="draweIndex">
      <div class="draweContent">
        <img src="../imger/WBWlogo02.png" alt="问标网" class="draweIcon01">
        <div class="draweSousuo">
          <p class="draweSstitle">让我们帮您快速找标，省心、省力、省钱！</p>
          <div class="drawePut">
            <div class="draweBar">
              <input class="draweInput01" type="text" placeholder="请输入手机号码" v-model="inputData">
          </div>
          <div class="draweAnniu" @click="fn1()">提交快速找标</div>
          </div>

        </div>
        <div class="draweContact">
          <img src="../imger/wbwxcx.png" alt="问标网商标查询" class="drawePicture" />
          <div class="drawePhone">
            <p class="draweWord01">咨询热线</p>
            <p class="draweWord02">400-850-8819</p>
            <p class="draweWord01">资深顾问</p>
            <p class="draweWord02">13380036087</p>
          </div>
        </div> 
      </div>
      <!-- 关闭此窗口的按钮，重新打开eject弹窗 -->
      <div class="draweError" @click="error"></div>
    </div>
</template>

<script>
import {message} from '../API/index'
export default {
  data(){
    return {
      inputData:'',
      userID:'',
      userkey:'',
      messagedata:{
        user_nickname:'',
        user_name:'',
        user_phone:'',
        user_id:0,
        message:'',
        source:'网站弹出窗',
        advisories:'',
        reg_id:'',
        reg_type:'',
        platform:'问标',
        brand_num:''
      },
    }
  },
  methods: {
    error(){
      var ejectbox = document.querySelector('.ejectbox')
      var draweIndex = document.querySelector('.draweIndex')
      ejectbox.style.left= -0.9375/* -15px -> -0.9375rem */+"rem"    //将窗口开启出来
      draweIndex.style.left = -100 + "%" //将抽屉退出
    },
    async fn1(){
      if(sessionStorage.getItem('userkey')){
        this.userID = JSON.parse(localStorage.getItem('user'))
        this.userkey = sessionStorage.getItem('userkey')
        this.messagedata.user_id = this.userID.id
        this.messagedata.user_phone = this.userID.phone
        this.messagedata.user_nickname = this.userID.name
      }else{
        this.userkey = sessionStorage.getItem('key')
      }
      const res = await message({
        key:this.userkey,
        phone:this.inputData,
        user_id:this.userID==""? 0:this.userID.id,
        message:JSON.parse(JSON.stringify(this.messagedata))
      })
      if(res.data.key){
        this.$message({
            type: 'success',
            message: '信息提交成功，等待专员联系'
          });
          this.inputData=""
      }
    }
  }

}
</script>

<style scoped lang="scss">
.draweIndex {
  position: fixed;
  left: -100%;
  bottom: 0;
  width: 100%;
  z-index: 100;
  height: 9.375rem /* 150px -> 9.375rem */;
  background-color: #4D89F1;
  transition-duration: 0.5s;
  .draweContent {
    width: 74.375rem /* 1190px -> 74.375rem */;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .draweIcon01 {
      width: 14.5rem /* 232px -> 14.5rem */;
      height: 6.25rem /* 100px -> 6.25rem */;
    }
    .draweSousuo {

    .draweSstitle {
      font-size: 1.125rem /* 18px -> 1.125rem */;
      color: #fff;
    }
    .drawePut {
      height: 2.6875rem /* 43px -> 2.6875rem */;
      margin-top: 0.625rem /* 10px -> 0.625rem */;
      display: flex;
      .draweBar {
        width: 24.0625rem /* 385px -> 24.0625rem */;
        height: 100%;
        background-color: #fff;
        margin-right: 1.125rem /* 18px -> 1.125rem */;
        display: flex;
        justify-content: center;
        align-items: center;
        .draweInput01 {
          border: none;
          height: 80%;
          width: 90%;
          outline: none;
          font-size: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      .draweAnniu {
        width: 7.6875rem /* 123px -> 7.6875rem */;
        height: 100%;
        text-align: center;
        line-height: 2.6875rem /* 43px -> 2.6875rem */;
        color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        background-color: #FF7E00;
        cursor: pointer;
      }
    }
    
  }
  .draweContact {
    width: 22.9375rem /* 367px -> 22.9375rem */;
    display: flex;
    .drawePicture {
      width: 7.5rem /* 120px -> 7.5rem */;
      height: 7.5rem /* 120px -> 7.5rem */;
    }
    .drawePhone {
      width: 12.5rem /* 200px -> 12.5rem */;
      margin-left: 0.9375rem /* 15px -> 0.9375rem */;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .draweWord01 {
        font-size: 1rem /* 16px -> 1rem */;
        color: #fff;
      }
      .draweWord02 {
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #fff;
      }
    }
  }
  }
  
  .draweError {
    width: 1.625rem /* 26px -> 1.625rem */;
    height: 1.625rem /* 26px -> 1.625rem */;
    background-image: url("../imger/error03.png");
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    right: 2.5rem /* 40px -> 2.5rem */;
    transform: translatey(-50%);
    cursor: pointer;
  }
}
</style>