<template>
  <div class="foot">
    <!-- 页面脚部的了解更多以及关于我们等 -->
    <div class="content">
      <!-- 中间内容 -->
      <div class="conBox">
        <!-- 第一竖栏 -->
        <div class="OneBox">
          <p class="word01">ABOUT US</p>
          <span class="word02">超6000万+商标大数据 免费商标查询服务</span>
          <!-- 蓝色隔开线 -->
          <div class="br"></div>
          <div class="onelast">
            <span class="word03">国家工商行政管理局 中国商标局备案机构</span>
            <img
              src="../imger/guohui.png"
              class="img01"
              alt="国家工商行政管理局 中国商标局备案机构"
            />
          </div>
        </div>

        <!-- 第二竖栏 -->
        <div class="TowBox">
          <p class="word04">商标注册流程</p>
          <!-- 蓝色隔开线 -->
          <div class="br"></div>
          <!-- 列表 -->
          <div class="Towlast">
            <span class="word05">1.在线检索商标</span>
            <span class="word05">2.注册/登录</span>
            <span class="word05">3.上传注册资料</span>
            <span class="word05">4.提交到商标局</span>
            <span class="word05">5.商标受理审核</span>
          </div>
        </div>

        <!-- 第三竖栏 -->
        <div class="ThreeBox">
          <p class="word04">问标网服务</p>
          <!-- 蓝色隔开线 -->
          <div class="br"></div>
          <!-- 列表 -->
          <div class="Towlast">
            <span class="word05" @click="fn1(1)">
              <img class="icon02" src="../imger/tuceng511.png" alt="商标服务" />
              商标服务</span
            >
            <span class="word05" @click="fn1(2)">
              <img class="icon02" src="../imger/tuceng511.png" alt="品牌服务" />
              品牌服务</span
            >
            <span class="word05" @click="fn1(3)">
              <img class="icon02" src="../imger/tuceng511.png" alt="专利版权" />
              专利版权</span
            >
            <span class="word05" @click="fn1(4)">
              <img class="icon02" src="../imger/tuceng511.png" alt="其他服务" />
              其他服务</span
            >
          </div>
        </div>

        <!-- 第四竖栏 -->
        <div class="ThreeBox">
          <p class="word04">关于我们</p>
          <!-- 蓝色隔开线 -->
          <div class="br"></div>
          <!-- 列表 -->
          <div class="Towlast">
            <router-link to="/contact">
              <span class="word05">
                <img
                  class="icon02"
                  src="../imger/tuceng511.png"
                  alt="公司简介"
                />
                公司简介</span
              >
            </router-link>
            <router-link to="/about">
              <span class="word05">
                <img
                  class="icon02"
                  src="../imger/tuceng511.png"
                  alt="联系我们"
                />
                联系我们</span
              >
            </router-link>
          </div>
        </div>

        <!-- 第五竖栏 -->
        <div class="FritBox">
          <p class="word04">联系我们</p>
          <!-- 蓝色隔开线 -->
          <div class="br"></div>
          <!-- 列表 -->
          <div class="Towlast">
            <span class="word05">广州问权知识产权服务有限公司</span>
            <span class="word05">咨询热线：400-850-8819</span>
            <div class="Applet">
              <div class="left">
                <img
                  src="../imger/wbwxcx.png"
                  class="icon01"
                  alt="问标网小程序"
                />
                <span class="word06">问标网小程序</span>
              </div>
              <div class="left">
                <img src="../imger/gzh.png" class="icon01" alt="问标网公众号" />
                <span class="word06">问标网公众号</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 版权备案号最底层栏 -->
    <div class="copyright">
      <div class="lastTitle">
        <a href="www.iwenbiao.com"
          >www.iwenbiao.com 版权所有©广州问权知识产权服务有限公司</a
        >
        <span>地址：广东省广州市海珠区（琶洲）中洲中心南塔B座15楼</span>
        <h6 class="beian" @click="linkDownload('https://beian.miit.gov.cn')">
          ICP备案号：粤ICP备20064697号
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  methods: {
    fn1(data) {
      this.$router.push({ path: "/server", query: { serve_state: data } });
      this.reload();
    },
    // 新窗口打开客服咨询
    linkDownload(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: #cdcdcd;
}
.foot {
  width: 100%;
}
.frit {
  flex-grow: 1;
}

.icon02 {
  width: 0.3125rem /* 5px -> 0.3125rem */;
  height: 0.5625rem /* 9px -> 0.5625rem */;
  margin-right: 0.75rem /* 12px -> 0.75rem */;
}
.content {
  width: 100%;
  height: 20.4375rem /* 327px -> 20.4375rem */;
  background-image: url("../imger/footback.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  .conBox {
    height: 100%;
    width: 74.375rem /* 1190px -> 74.375rem */;
    display: flex;
    .OneBox {
      width: 11.75rem /* 188px -> 11.75rem */;
      .br {
        width: 7.75rem /* 124px -> 7.75rem */;
        background-color: #4d89f1;
        border: 0.125rem /* 1px -> 0.0625rem */ solid #4d89f1;
        margin-top: 0.9375rem /* 15px -> 0.9375rem */;
      }
      .word01 {
        margin-top: 3.5625rem /* 57px -> 3.5625rem */;
        color: #fff;
        font-weight: bold;
        font-size: 2.125rem /* 34px -> 2.125rem */;
      }
      .word02 {
        color: #c4c4c4;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        line-height: 2.5rem /* 40px -> 2.5rem */;
      }
      .onelast {
        height: 3.25rem /* 52px -> 3.25rem */;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.8125rem /* 29px -> 1.8125rem */;
        .img01 {
          width: 3.0625rem /* 49px -> 3.0625rem */;
          height: 100%;
        }
        .word03 {
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #fff;
        }
      }
    }
    .TowBox {
      margin-left: 5.375rem /* 86px -> 5.375rem */;
      width: 6.875rem /* 110px -> 6.875rem */;
      height: 100%;
      .word04 {
        color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        margin-top: 3.1875rem /* 51px -> 3.1875rem */;
      }
      .br {
        width: 2rem /* 32px -> 2rem */;
        background-color: #4d89f1;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #4d89f1;
        margin-top: 1.25rem /* 20px -> 1.25rem */;
      }
      .Towlast {
        width: 100%;
        // height: 9.625rem /* 154px -> 9.625rem */;
        margin-top: 1.6875rem /* 27px -> 1.6875rem */;

        .word05 {
          display: block;
          margin-bottom: 0.9375rem /* 15px -> 0.9375rem */;
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #c4c4c4;
        }
      }
    }
    .ThreeBox {
      margin-left: 5.375rem /* 86px -> 5.375rem */;
      width: 6.875rem /* 110px -> 6.875rem */;
      height: 100%;
      .word04 {
        color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        margin-top: 3.1875rem /* 51px -> 3.1875rem */;
      }
      .br {
        width: 2rem /* 32px -> 2rem */;
        background-color: #4d89f1;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #4d89f1;
        margin-top: 1.25rem /* 20px -> 1.25rem */;
      }
      .Towlast {
        width: 100%;
        // height: 9.625rem /* 154px -> 9.625rem */;
        margin-top: 1.6875rem /* 27px -> 1.6875rem */;

        .word05 {
          display: block;
          margin-bottom: 0.9375rem /* 15px -> 0.9375rem */;
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #c4c4c4;
          cursor: pointer;
        }
      }
    }
    .FritBox {
      margin-left: 11rem /* 176px -> 11rem */;
      flex-grow: 1;
      .word04 {
        color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        margin-top: 3.1875rem /* 51px -> 3.1875rem */;
      }
      .br {
        width: 2rem /* 32px -> 2rem */;
        background-color: #4d89f1;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #4d89f1;
        margin-top: 1.25rem /* 20px -> 1.25rem */;
      }
      .Towlast {
        width: 100%;
        // height: 9.625rem /* 154px -> 9.625rem */;
        margin-top: 1.6875rem /* 27px -> 1.6875rem */;

        .word05 {
          display: block;
          margin-bottom: 0.9375rem /* 15px -> 0.9375rem */;
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #c4c4c4;
        }
        .Applet {
          width: 15.625rem /* 250px -> 15.625rem */;
          display: flex;
          justify-content: space-between;
          .left {
            width: 6.875rem /* 110px -> 6.875rem */;
            height: 7.5rem /* 120px -> 7.5rem */;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .icon01 {
              width: 6.25rem /* 100px -> 6.25rem */;
              height: 6.25rem /* 100px -> 6.25rem */;
            }
            .word06 {
              font-size: 0.73125rem /* 11.7px -> 0.73125rem */;
            }
          }
        }
      }
    }
  }
}
.copyright {
  width: 100%;
  height: 4.625rem /* 74px -> 4.625rem */;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  .lastTitle {
    width: 74.375rem /* 1190px -> 74.375rem */;
    display: flex;
    justify-content: space-between;
    color: #cdcdcd;
    font-size: 0.875rem /* 14px -> 0.875rem */;
    .beian {
      font-weight: 400;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      cursor: pointer;
    }
  }
}
</style>