<template>
  <div>
    <div class="box" v-for="(item, index) in newdata" :key="index">
      <div class="left">
        <p class="date">{{ index + 1 }}</p>
        <p class="year">{{ item.create_time.slice(0, 10) }}</p>
      </div>
      <div class="right">
        <p class="word01" @click="fn1(item.id)">{{ item.title }}</p>
        <p class="word02" v-html="removeHtmlStyle(item.content)"></p>
      </div>
    </div>
  </div>
</template>

<script>
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  props: ["rightNewData"],
  data() {
    return {
      newdata: this.rightNewData, //新闻数据
    };
  },
  watch: {
    rightNewData(nData, oData) {
      if (nData) {
        this.newdata = nData;
      }
    },
  },
  methods: {
    fn1(data) {
      var UPID = [];
      for (let i = 0; i < this.newdata.length; i++) {
        let obj = { id: this.newdata[i].id, name: this.newdata[i].title };
        UPID[i] = obj;
      }
      sessionStorage.setItem("newsIdArray", JSON.stringify(UPID));
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
    },
    removeHtmlStyle(html) {
      let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "");
      }
      return newHtml;
    },
  },
};
</script>

<style scoped lang="scss">
.box:hover{
  .right{
    .word01{
      color: #4d89f1;
    }
  }
}
.box {
  width: 100%;
  height: 8.125rem /* 130px -> 8.125rem */;
  border-bottom: 0.0625rem /* 1px -> 0.0625rem */ dashed #cdcdcd;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .left {
    .date {
      margin-top: 1.25rem /* 20px -> 1.25rem */;
      font-size: 2.6875rem /* 43px -> 2.6875rem */;
      color: #666666;
    }
    .year {
      // margin-top: 15px;
      font-size: 0.75rem /* 12px -> 0.75rem */;
      color: #999999;
    }
  }
  .right {
    width: 28.8125rem /* 461px -> 28.8125rem */;
    height: 8.125rem /* 130px -> 8.125rem */;
    .word01 {
      height: 2.5rem /* 40px -> 2.5rem */;
      font-size: 1.125rem /* 18px -> 1.125rem */;
      color: #333333;
      font-weight: bold;
      margin-top: 1.875rem /* 30px -> 1.875rem */;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .word02 {
      height: 3.125rem /* 50px -> 3.125rem */;
      font-size: 1rem /* 16px -> 1rem */;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.25rem /* 20px -> 1.25rem */; //
      height: 2.5rem /* 40px -> 2.5rem */; ///* 2行隐藏就两倍，三行隐藏就三倍*/
    }
  }
}
</style>