
import request from './url'
import chakan from './urlCopy'

//查看个人信息
export const userInfo = async({...params}) => {
  const res = await request({
      url: '/user/api/v1/web/user/userinfo',
      method: 'post',
      data:params,
  })
  return res.data
}

//修改个人信息
export const userInfoModify= async({...params}) => {
  
 const res = await request({
     url: '/user/api/v1/web/user/change_user',
     method: 'post',
     data:params,
 })
 return res.data
}

// //查看关联企业信息
// export const enterpriseInfo= async({...params}) => {
//   
//  const res = await request({
//      url: '/api/v1/web/user/user_relevance_firm',
//      method: 'post',
//      data:params,
//  })
//  return res.data
// }

// //修改关联企业信息
// export const enterpriseInfoModify= async({...params}) => {
//   
//  const res = await request({
//      url: '/api/v1/web/user/chamge_user_relevance_firm',
//      method: 'post',
//      data:params,
//  })
//  return res.data
// }

//查看收件地址信息
export const addressInfo= async({...params}) => {
  
 const res = await request({
     url: '/user/api/v1/web/user/user_consignee_address',
     method: 'post',
     data:params,
 })
 return res.data
}

// 修改收件地址信息
export const addressInfoModify= async({...params}) => {
 const res = await request({
     url: '/user/api/v1/web/user/change_user_consignee_address',
     method: 'post',
     data:params,
 })
 return res.data
}

//添加收件地址
export const addressInfoAdd= async({...params}) => {
 const res = await request({
     url: '/user/api/v1/web/user/append_user_consignee_address',
     method: 'post',
     data:params,
 })
 return res.data
}

// //添加关联企业
// export const enterpriseInfoAdd= async({...params}) => {
//   
//  const res = await request({
//      url: '/api/v1/web/user/append_User_relevance_firm',
//      method: 'post',
//      data:params,
//  })
//  return res.data
// }