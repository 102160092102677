var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Journalism"},[_c('div',{staticClass:"top"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"right"},[_c('router-link',{attrs:{"to":"/news"}},[_c('a',{staticClass:"mach"},[_vm._v("查看更多")]),_vm._v(" "),_c('img',{staticClass:"icon01",attrs:{"src":require("../../imger/come02.png"),"alt":"问标网"}})])],1)]),_vm._v(" "),_c('div',{staticClass:"topBox"},[_c('div',{staticClass:"top"},_vm._l((_vm.newClass),function(item,index){return _c('div',{key:index,staticClass:"boxlei",style:({ color: _vm.state == index ? '#4D89F1' : '' }),on:{"click":function($event){return _vm.fn2(index)}}},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])}),0),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"lang",style:({
          left:
            _vm.state == 0
              ? '3.4375rem'
              : _vm.state == 1
              ? '14.0625rem'
              : _vm.state == 2
              ? '24.6875rem'
              : '35.3125rem',
        })})])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"icon02",attrs:{"src":_vm.state == 0
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
            : _vm.state == 1
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
            : _vm.state == 2
            ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
            : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png',"alt":_vm.state == 0
            ? '热点咨询'
            : _vm.state == 1
            ? '商标知识'
            : _vm.state == 2
            ? '政策法规'
            : '经典案例'}}),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"word01",on:{"click":function($event){return _vm.fn1(_vm.news.id)}}},[_vm._v(_vm._s(_vm.news.title))]),_vm._v(" "),_c('p',{staticClass:"word02",domProps:{"innerHTML":_vm._s(_vm.removeHtmlStyle(_vm.news.content))}}),_vm._v(" "),_c('a',{staticClass:"word03"},[_vm._v("MORE >>")])])]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('New',{attrs:{"rightNewData":_vm.rightNewData}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"lan"}),_vm._v(" "),_c('p',{staticClass:"title"},[_vm._v("行业热点新闻")])])}]

export { render, staticRenderFns }