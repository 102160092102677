
import request from './url'

//发送验证码
export const sengOut = async({...params}) => {
   
  const res = await request({
      url: '/user/api/v1/web/user/send_message',
      method: 'post',
      data:params,
  })
  return res.data
}

//校验验证码
export const receive= async({...params}) => {
  
 const res = await request({
     url: '/user/api/v1/web/user/verify_message',
     method: 'post',
     data:params,
 })
 return res.data
}

//请求SESSION(登录之后的请求不一样的key)
export const newkeyCode= async({...params}) => {
 const res = await request({
     url: '/api/v1/web/jwt/session',
     method: 'post',
     data:params,
 })
 return res.data
}