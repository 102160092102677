<template>
  <div class="header">
    <!-- 头部文字框 -->
    <div class="content">
        <div class="left">
          <router-link to="/">
            <a class="wb">问标网官方唯一网站（www.iwenbiao.com）</a>
          </router-link>
          <span class="wb lf" @click="AddFavorite">收藏夹</span>
        </div>   
        <div class="right">
          <a href="https://www.iwenbiao.com/sitemap.xml">
          <p class="wb"><img src="../imger/tuceng06.png" class="icon" alt="网站地图"/>网站地图</p>
          </a>
          <router-link :to="userData==''?'/login':'/user'">
            <p  class="wb lf2">
              <img src="../imger/tuceng07.png" class="icon2" alt="问标网官网登录/注册，问标网官网个人中心"/>{{userData==''? '你好！请登录':userData.name}}
            </p>
          </router-link>
          <router-link :to="userData==''?'/login':''">
            <p @click="userData==''? '':fn2()"  class="wb lf2">
              <img src="../imger/tuceng08.png" class="icon2" alt="问标网官网登录/注册"/>{{userData==''? '免费注册':'退出登录'}}
            </p>
          </router-link>
        </div>
      </div>
  </div>
</template>

<script>
import {userInfo} from '../API/user'
export default {
  inject:['reload'],
  data(){
    return{
      userData:'',
    }
  },
  created(){
    
    this.userInfo()
  },
  methods:{
    //收藏本站
      AddFavorite() {
          try {
              window.external.addFavorite("http://www.iwenbiao.com", "商标查询_商标查询网_免费查询注册商标网-问标网商标查询");
          }
          catch (e) {
              try {
                  window.sidebar.addPanel("商标查询_商标查询网_免费查询注册商标网-问标网商标查询", "http://www.iwenbiao.com", "");
              }
              catch (e) {
                  alert("抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加");
              }
          }
      },
    // 查询个人信息
    async userInfo(){
      if(localStorage.getItem('user')){
        this.userData = JSON.parse(localStorage.getItem('user')) 
        }
    },
    // 退出登录
    fn2(){
      this.$confirm('是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '账号已退出，请重新登录！',
          });
          this.$router.push({path:'/'})
          sessionStorage.clear()
          localStorage.removeItem('user')
          this.reload();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '出错了，请重新尝试'
          });          
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.wb{
  color: #999999;
  font-size: .75rem /* 12px -> .75rem */;
  // transform: scale(0.8);
  // line-height: 1.5625rem /* 25px -> 1.5625rem */;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.lf{
  margin-left: 3.1875rem;
}
.lf2{
  margin-left: 2.1875rem;
}
.header {
  height: 1.875rem /* 30px -> 1.875rem */;
  width: 100%;
  background-color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
    .content {
      height: 100%;
      width: 74.375rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height:100%;
        display: flex;
        align-items: center;
      }
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        .icon {
          width: .625rem /* 10px -> .625rem */;
          height: 1rem /* 16px -> 1rem */;
          margin-right: .3125rem;
        }
        .icon2 {
          width: .9375rem /* 15px -> .9375rem */;
          height: 1rem /* 16px -> 1rem */;
          margin-right: .3125rem;
        }
      }
    }
    
  
}
</style>
