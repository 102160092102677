// 加密js文件引入
import { encryptDes, decryptDes, generatekey } from "@/config/DES";
import createRouter from '../router/index'
(function () {
  if (navigator == "is undefined") {

  } else {
    // 判断访问设备
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      const hrefURL = window.location.pathname    //获取当前URL的文件路径
      const hrefdata = window.location.search     //获取当前URL的文件路径问号后面跟着的参数

      // alert('手机端',hrefURL,hrefdata)
      // 判断路径，跳转到对应的手机端网站
      // 首页
      if (hrefURL == "/") {
        window.location.href = "https://m.iwenbiao.com"   //使用js重定向到手机端页面
      }
      // 商标分类
      if (hrefURL == "/sblb") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL  //使用js重定向到手机端页面
      }
      // 登录界面
      if (hrefURL == "/login") {
        window.location.href = "https://m.iwenbiao.com"   //使用js重定向到手机端页面
      }
      // 商标查询结果分类页
      if (hrefURL.slice(0, 7) == "/rearch") {
        const router = createRouter();
        var keys = generatekey();
        const index = window.location.pathname.indexOf(".html");
        const idpass = window.location.pathname.slice(8, index);
        // const dess = JSON.parse(decryptDes(idpass, keys));
        const dess = JSON.parse(decryptDes(idpass, keys)).tradName
        window.location.href = `https://m.iwenbiao.com/rearch?tranName=${dess}`   //使用js重定向到手机端页面
        router.push('/')
      }
      // 商标详情页
      if (hrefURL.slice(0, 8) == "/details") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL  //使用js重定向到手机端页面
      }
      // 个人用户信息
      if (hrefURL == "/user") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 商标在线自主注册页
      if (hrefURL == "/zcsb") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 商标辅助注册页
      if (hrefURL == "/coach") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 品牌设计页面
      if (hrefURL == "/logo") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 网站建设页面
      if (hrefURL == "/wzjs") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 搜索站位页面
      if (hrefURL == "/seat") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 新闻资讯
      if (hrefURL == "/news") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 新闻详情
      if (hrefURL.slice(0, 8) == "/newInfo") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 关于我们
      if (hrefURL == "/about") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 驳回复审
      if (hrefURL == "/reject") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 无执照流程
      if (hrefURL == "/licence") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 商标注册保险
      if (hrefURL == "/bxzc") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 服务中心
      if (hrefURL == "/server") {
        window.location.href = "https://m.iwenbiao.com" + hrefURL   //使用js重定向到手机端页面
      }
      // 查商标成功率/联系我们页面/个人用户信息/登录界面
      if (hrefURL == "/success" ||
        hrefURL == "/contact" ||
        hrefURL == "/user" ||
        hrefURL == "/login") {
        window.location.href = "https://m.iwenbiao.com"  //使用js重定向到手机端页面
      }

    } else {
      // alert('pc端')
    }
  }


}())