(function(){
  function resize(){
    
    var DesigndraftWidth = 120; //设计稿的宽度等于1920px,的出伸缩比得出120
    var width = window.document.documentElement.clientWidth;
    if(width >= 1490){
      document.querySelector('html').style.fontSize =width/DesigndraftWidth + "px"
    }else{
      width=1490
    }
    
  }

  window.onresize = function(){
    resize()
  }
  document.addEventListener('DOMContentLoaded',resize)
}())