import request from './url'

//获取新闻类别
export const allNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news_classify/list',
     method: 'post',
     data:params,
 })
 return res.data
}

//根据时间获取列表
export const timeNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news/list_time',
     method: 'post',
     data:params,
 })
 return res.data
}

//根据类别获取到简易信息
export const ClassNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news/list_type',
     method: 'post',
     data:params,
 })
 return res.data
}

//查看单个新闻
export const oneNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news/info',
     method: 'post',
     data:params,
 })
 return res.data
}

//获取到置顶新闻信息
export const topNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news/top',
     method: 'post',
     data:params,
 })
 return res.data
}

//获取推荐新闻
export const recommendNew= async({...params}) => {
  
 const res = await request({
     url: '/news/api/v1/web/news/recommend',
     method: 'post',
     data:params,
 })
 return res.data
}