<template>
  <div class="Journalism">
    <div class="top">
      <div class="left">
        <div class="lan"></div>
        <p class="title">行业热点新闻</p>
      </div>
      <div class="right">
        <router-link to="/news">
          <a class="mach">查看更多</a>
          <img src="../../imger/come02.png" alt="问标网" class="icon01" />
        </router-link>
      </div>
    </div>
    <div class="topBox">
      <div class="top">
        <div
          class="boxlei"
          @click="fn2(index)"
          :style="{ color: state == index ? '#4D89F1' : '' }"
          v-for="(item, index) in newClass"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="bottom">
        <div
          class="lang"
          :style="{
            left:
              state == 0
                ? '3.4375rem'
                : state == 1
                ? '14.0625rem'
                : state == 2
                ? '24.6875rem'
                : '35.3125rem',
          }"
        ></div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <img
          class="icon02"
          :src="
            state == 0
              ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%83%AD%E7%82%B9%E8%B5%84%E8%AE%AF-web.png'
              : state == 1
              ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E5%95%86%E6%A0%87%E7%9F%A5%E8%AF%86-web.png'
              : state == 2
              ? 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E6%94%BF%E7%AD%96%E6%B3%95%E8%A7%84-web.png'
              : 'https://iwenbiao-admin.oss-cn-guangzhou.aliyuncs.com/%E7%BB%8F%E5%85%B8%E6%A1%88%E4%BE%8B-web.png'
          "
          :alt="state == 0
              ? '热点咨询'
              : state == 1
              ? '商标知识'
              : state == 2
              ? '政策法规'
              : '经典案例'
              "
        />
        <div class="bottom" >
          <p class="word01" @click="fn1(news.id)">{{ news.title }}</p>
          <p class="word02" v-html="removeHtmlStyle(news.content)"></p>
            <a class="word03">MORE >></a>
        </div>
      </div>
      <div class="right">
        <New :rightNewData="rightNewData" />
      </div>
    </div>
  </div>
</template> 

<script>
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
import New from "./components/new.vue";
import { allNew, ClassNew } from "../../API/new";
import { keyCode, newIP } from "../../API/index";
export default {
  components: {
    New,
  },
  data() {
    return {
      news: "",
      newClass: "",
      state: 0,
      rightNewData: [],
      ip: "",
    };
  },
  async created() {
    await this.keyCode();
    await this.allNew();
    await this.ClassNew(this.newClass[0].name);
  },
  methods: {
    //  获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == "" || duan == null || duan == undefined) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    async allNew() {
      const res = await allNew({
        key: sessionStorage.getItem("key"),
        platform:'问标'
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        this.newClass = res.data.list;
      }
    },
    async ClassNew(data) {
      const res = await ClassNew({
        key: sessionStorage.getItem("key"),
        classify: data,
        page: 1,
        page_size: 20,
        platform:'问标'
      });
      if(res.data.key){
        await sessionStorage.setItem("key", res.data.key);
        if(res.data.list!==null){
          this.news=res.data.list[0]
          res.data.list.shift()
          this.rightNewData = res.data.list.slice(0,4)

        }else{
          this.rightNewData=[]
          this.news=''
        }
      }
    },
    fn1(data) {
      var UPID = [];
      for (let i = 0; i < this.rightNewData.length; i++) {
        let obj = { id: this.rightNewData[i].id, name: this.rightNewData[i].title };
        UPID[i] = obj;
      }
      sessionStorage.setItem("newsIdArray", JSON.stringify(UPID));
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
    },
    async fn2(index) {
      this.state = index;
      await this.ClassNew(this.newClass[index].name);
    },
    removeHtmlStyle(html) {
      let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "");
      }
      return newHtml;
    },
  },
};
</script>

<style  scoped lang="scss">
.Journalism {
  margin-top: 2.125rem /* 34px -> 2.125rem */;
  padding-bottom: 9.375rem /* 150px -> 9.375rem */;
  display: flex;
  flex-direction: column;
  .top {
    height: 2.1875rem /* 35px -> 2.1875rem */;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 100%;
      display: flex;
      .lan {
        width: 0.375rem /* 6px -> 0.375rem */;
        height: 100%;
        background-color: #508df1;
        margin-right: 0.75rem /* 12px -> 0.75rem */;
      }
      .title {
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
        line-height: 2.0625rem /* 33px -> 2.0625rem */;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .mach {
        font-size: 1rem /* 16px -> 1rem */;
        color: #666666;
        margin-right: 0.5625rem /* 9px -> 0.5625rem */;
      }
      .icon01 {
        width: 0.5625rem /* 9px -> 0.5625rem */;
        height: 1rem /* 16px -> 1rem */;
      }
    }
  }
  .topBox {
    width: 43.125rem /* 690px -> 43.125rem */;
    height: 4.375rem /* 70px -> 4.375rem */;
    margin-bottom: 1.875rem /* 30px -> 1.875rem */;
    margin-top: 1.875rem /* 30px -> 1.875rem */;
    box-shadow: 0 0 0.1875rem /* 3px -> 0.1875rem */ 0.1875rem
      /* 3px -> 0.1875rem */ rgba($color: #000000, $alpha: 0.07);
    border-radius: 0.625rem /* 10px -> 0.625rem */;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .top {
      width: 100%;
      height: 3.4375rem /* 55px -> 3.4375rem */;
      display: flex;
      .boxlei {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem /* 24px -> 1.5rem */;
        cursor: pointer;
      }
      .boxlei:hover{
        color: #4d89f1;
      }
    }
    .bottom {
      width: 100%;
      height: 0.3125rem /* 5px -> 0.3125rem */;
      display: flex;
      .lang {
        width: 4.125rem /* 66px -> 4.125rem */;
        height: 0.3125rem /* 5px -> 0.3125rem */;
        background: #4d89f1;
        border-radius: 0.1563rem /* 2.5px -> 0.1563rem */;
        position: relative;
        left: 14.0625rem /* 225px -> 14.0625rem */; //170px
        transition-duration: 0.3s;
      }
    }
  }
  .content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    .left {
      width: 40rem /* 640px -> 40rem */;
      height: 100%;
      margin-top: 2.0625rem /* 33px -> 2.0625rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon02 {
        height: 20.625rem /* 330px -> 20.625rem */;
        width: 100%;
        // background-repeat: no-repeat;
        margin-bottom: 1.125rem /* 18px -> 1.125rem */;
      }
      .bottom:hover{
        .word01{
          color: #4d89f1;
        }
      }
      .bottom {
        width: 37.1875rem /* 595px -> 37.1875rem */;
        cursor: pointer;
        .word01 {
          height: 3.125rem /* 50px -> 3.125rem */;
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #333333;
          font-weight: bold;
          margin-bottom: 0.625rem /* 10px -> 0.625rem */;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          
        }
        .word02 {
          width: 37rem /* 592px -> 37rem */;
          font-size: 1rem /* 16px -> 1rem */;
          color: #666666;
          line-height: 1.875rem /* 30px -> 1.875rem */;
          margin-bottom: 1.25rem /* 20px -> 1.25rem */;
          overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.25rem /* 20px -> 1.25rem */; //
        height: 2.5rem /* 40px -> 2.5rem */; ///* 2行隐藏就两倍，三行隐藏就三倍*/
        }
        .word03 {
          font-size: 1rem /* 16px -> 1rem */;
          color: #999999;
          margin-left: 1.8125rem /* 29px -> 1.8125rem */;
        }
      }
    }
    .right {
      width: 33.625rem /* 538px -> 33.625rem */;
      margin-top: 0.9375rem /* 15px -> 0.9375rem */;
    }
  }
}
</style>